export const rounds: RoundProps[] = [
  {
    title: 'Round One',
    seeds: [
      {
        "id": 1,
        "teams": [
          { "seed": 1, "name": "UConn" },
          { "seed": 16, "name": "Stetson" }
        ],
        "date": "3/22",
        "time": "2:45 PM"
      },
      {
        "id": 2,
        "teams": [
          { "seed": 8, "name": "Fla. Atlantic" },
          { "seed": 9, "name": "Northwestern" }
        ],
        "date": "3/22",
        "time": "12:15 PM"
      },
      {
        "id": 3,
        "teams": [
          { "seed": 5, "name": "San Diego St." },
          { "seed": 12, "name": "UAB" }
        ],
        "date": "3/22",
        "time": "1:45 PM"
      },
      {
        "id": 4,
        "teams": [
          { "seed": 4, "name": "Auburn" },
          { "seed": 13, "name": "Yale" }
        ],
        "date": "3/22",
        "time": "4:15 PM"
      },
      {
        "id": 5,
        "teams": [
          { "seed": 6, "name": "BYU" },
          { "seed": 11, "name": "Duquesne" }
        ],
        "date": "3/21",
        "time": "12:40 PM"
      },
      {
        "id": 6,
        "teams": [
          { "seed": 3, "name": "Illinois" },
          { "seed": 14, "name": "Morehead St." }
        ],
        "date": "3/21",
        "time": "3:10 PM"
      },
      {
        "id": 7,
        "teams": [
          { "seed": 7, "name": "Washington St." },
          { "seed": 10, "name": "Drake" }
        ],
        "date": "3/21",
        "time": "10:05 PM"
      },
      {
        "id": 8,
        "teams": [
          { "seed": 2, "name": "Iowa St." },
          { "seed": 15, "name": "South Dakota St." }
        ],
        "date": "3/21",
        "time": "7:35 PM"
      },
      {
        "id": 9,
        "teams": [
          { "seed": 1, "name": "North Carolina" },
          { "seed": 16, "name": "Wagner" }
        ],
        "date": "3/21",
        "time": "2:45 PM"
      },
      {
        "id": 10,
        "teams": [
          { "seed": 8, "name": "Mississippi St." },
          { "seed": 9, "name": "Michigan St." }
        ],
        "date": "3/21",
        "time": "12:15 PM"
      },
      {
        "id": 11,
        "teams": [
          { "seed": 5, "name": "Saint Mary's (CA)" },
          { "seed": 12, "name": "Grand Canyon" }
        ],
        "date": "3/22",
        "time": "10:05 PM"
      },
      {
        "id": 12,
        "teams": [
          { "seed": 4, "name": "Alabama" },
          { "seed": 13, "name": "Col. of Charleston" }
        ],
        "date": "3/22",
        "time": "7:35 PM"
      },
      {
        "id": 13,
        "teams": [
          { "seed": 6, "name": "Clemson" },
          { "seed": 11, "name": "New Mexico" }
        ],
        "date": "3/22",
        "time": "3:10 PM"
      },
      {
        "id": 14,
        "teams": [
          { "seed": 3, "name": "Baylor" },
          { "seed": 14, "name": "Colgate" }
        ],
        "date": "3/22",
        "time": "12:40 PM"
      },
      {
        "id": 15,
        "teams": [
          { "seed": 7, "name": "Dayton" },
          { "seed": 10, "name": "Nevada" }
        ],
        "date": "3/21",
        "time": "4:30 PM"
      },
      {
        "id": 16,
        "teams": [
          { "seed": 2, "name": "Arizona" },
          { "seed": 15, "name": "Long Beach St." }
        ],
        "date": "3/21",
        "time": "2:00 PM"
      },
      {
        "id": 17,
        "teams": [
          { "seed": 1, "name": "Purdue" },
          { "seed": 16, "name": "Grambling" }
        ],
        "date": "3/22",
        "time": "7:25 PM"
      },
      {
        "id": 18,
        "teams": [
          { "seed": 8, "name": "Utah St." },
          { "seed": 9, "name": "TCU" }
        ],
        "date": "3/22",
        "time": "9:55 PM"
      },
      {
        "id": 19,
        "teams": [
          { "seed": 5, "name": "Gonzaga" },
          { "seed": 12, "name": "McNeese" }
        ],
        "date": "3/21",
        "time": "7:25 PM"
      },
      {
        "id": 20,
        "teams": [
          { "seed": 4, "name": "Kansas" },
          { "seed": 13, "name": "Samford" }
        ],
        "date": "3/21",
        "time": "9:55 PM"
      },
      {
        "id": 21,
        "teams": [
          { "seed": 6, "name": "South Carolina" },
          { "seed": 11, "name": "Oregon" }
        ],
        "date": "3/21",
        "time": "4:00 PM"
      },
      {
        "id": 22,
        "teams": [
          { "seed": 3, "name": "Creighton" },
          { "seed": 14, "name": "Akron" }
        ],
        "date": "3/21",
        "time": "1:30 PM"
      },
      {
        "id": 23,
        "teams": [
          { "seed": 7, "name": "Texas" },
          { "seed": 10, "name": "Colorado St." }
        ],
        "date": "3/21",
        "time": "6:50 PM"
      },
      {
        "id": 24,
        "teams": [
          { "seed": 2, "name": "Tennessee" },
          { "seed": 15, "name": "Saint Peter's" }
        ],
        "date": "3/21",
        "time": "9:20 PM"
      },
      {
        "id": 25,
        "teams": [
          { "seed": 1, "name": "Houston" },
          { "seed": 16, "name": "Longwood" }
        ],
        "date": "3/22",
        "time": "9:20 PM"
      },
      {
        "id": 26,
        "teams": [
          { "seed": 8, "name": "Nebraska" },
          { "seed": 9, "name": "Texas A&M" }
        ],
        "date": "3/22",
        "time": "6:50 PM"
      },
      {
        "id": 27,
        "teams": [
          { "seed": 5, "name": "Wisconsin" },
          { "seed": 12, "name": "James Madison" }
        ],
        "date": "3/22",
        "time": "9:40 PM"
      },
      {
        "id": 28,
        "teams": [
          { "seed": 4, "name": "Duke" },
          { "seed": 13, "name": "Vermont" }
        ],
        "date": "3/22",
        "time": "7:10 PM"
      },
      {
        "id": 29,
        "teams": [
          { "seed": 6, "name": "Texas Tech" },
          { "seed": 11, "name": "NC State" }
        ],
        "date": "3/21",
        "time": "9:40 PM"
      },
      {
        "id": 30,
        "teams": [
          { "seed": 3, "name": "Kentucky" },
          { "seed": 14, "name": "Oakland" }
        ],
        "date": "3/21",
        "time": "7:10 PM"
      },
      {
        "id": 31,
        "teams": [
          { "seed": 7, "name": "Florida" },
          { "seed": 10, "name": "Colorado" }
        ],
        "date": "3/22",
        "time": "4:30 PM"
      },
      {
        "id": 32,
        "teams": [
          { "seed": 2, "name": "Marquette" },
          { "seed": 15, "name": "Western Ky." }
        ],
        "date": "3/22",
        "time": "2:00 PM"
      }
    ],
  },
];
