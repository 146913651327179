import React from 'react';

const baseUrl = 'https://104.131.0.17:1422/logos/';

const TeamStatCard = ({ team, isHovered }) => (


  <div className={`bg-gray-800 p-4 rounded-lg shadow-lg transform transition-all duration-300 ease-in-out -translate-y-full left-0 right-0 mx-auto w-auto max-w-[calc(100%+1rem)] ${isHovered ? 'block' : 'hidden'}`} style={{ top: '100%' }}>
    <img src={`${baseUrl}${team?.teamId}.png`} alt={`${team?.teamMarket} ${team?.teamName} Logo`} className="h-10 w-10 rounded-full" />
    <span className="text-lg text-white font-medium">{team?.teamMarket} {team?.teamName}</span>
    <div className="overflow-x-auto">
      <table className="min-w-full text-sm divide-y divide-gray-200">
        <thead className="bg-gray-800">
          <tr>
            <th scope="col" className="px-6 py-3 text-white text-left font-medium">
              Stat
            </th>
            <th scope="col" className="px-6 py-3 text-white text-left font-medium">
              Value
            </th>
          </tr>
        </thead>
        <tbody className="bg-gray-600 divide-y text-white divide-gray-200">
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Overall Record</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.overallWins} - {team.overallLosses}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Conference Record</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.confWins} - {team.confLosses}</td>
          </tr>
          {/* Add more rows for each stat as needed */}
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Points Scored Per Game</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.ptsScoredPg}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Strength of Schedule</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.sos}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Rebounds Per Game</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.rebPg}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Free Throws Attempted Per Game</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.ftaPg}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Assists Per 40</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.astP40}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Blocks</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.blk}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Bench Points Per Game</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.benchPtsPg}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Net Rating - Adjusted</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.netRtgAdj}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Pace - Adjusted</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.paceAdj}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Offensive Rating - Adjusted</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.ortgAdj}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Defensive Rating - Adjusted</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.drtgAdj}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Free Throw Percentage</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.ftPct}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Three-Point Percentage</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.fg3Pct}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Two-Point Percentage</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.fg2Pct}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Assist Ratio</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.astRatio}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Turnovers Per Game</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.tovPg}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Blocks Per Game</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.blkPg}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Points Scored Per Game</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.ptsScoredPg}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Rebounds Per Game</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.rebPg}</td>
          </tr>
          <tr>
            <td className="px-6 py-1 whitespace-nowrap">Free Throws Attempted Per Game</td>
            <td className="px-6 py-1 whitespace-nowrap">{team.ftaPg}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  );


  export default TeamStatCard;
